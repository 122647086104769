<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{ item.title }}</span>
    </v-subheader>

    <v-list-item
      @click="deleteCrm"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="error--text"
          v-text="'Excluir'"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteCrm() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: 'Deseja mesmo deletar este CRM?',
        confirm: () => {
          this.$api
            .post(`crm/delete`, { id: this.item.id })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'CRM excluido com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  contextMenu.action('crms')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir este CRM',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>
